import React from 'react'

import PropTypes from 'prop-types'

import MessageKeys from '../../../../languages/en/en.json'
import { IN_PROGRESS } from '../../../../utils/constants'
import { getTokensAndCredsByStage } from '../../../../utils/utils'

/*
 * This function returns the initial (default) card for the add new sensor graph flow
 */
function SensorGraphDefault (props) {
  const vendor = getTokensAndCredsByStage()[1].vendorName
  return (
    <tr>
      <td colSpan='2'>
        <div className="fr-cards-section fr-homepage-bordered-section">
          <ul className="fr-cards-list">
            <li>
              <h3 className="fr-section-subtitle">{vendor === 'ST' ? MessageKeys.instructionCards.tutorials.st.header : MessageKeys.instructionCards.tutorials.esp.header}</h3>
              <p> {vendor === 'ST' ? MessageKeys.instructionCards.tutorials.st.subheader : MessageKeys.instructionCards.tutorials.esp.subheader} </p>
              <a onClick={() => { props.updateWorkflow(IN_PROGRESS) }}>
                <div className='fr-button fr-variant-secondary' test-id='add-graph-button'>
                  Add Sensor Graph
                </div>
              </a>
            </li>
          </ul>
        </div>
      </td>
    </tr>

  )
}

export default SensorGraphDefault

SensorGraphDefault.propTypes = {
  deviceName: PropTypes.string,
  updateWorkflow: PropTypes.func
}
