import * as React from 'react'

import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import PropTypes from 'prop-types'

function GraphCards (props) {
  return props.renderGraph
    ? (<ColumnLayout columns={ 2 }>{ props.graphs }</ColumnLayout>)
    : (<></>)
}

GraphCards.propTypes = {
  renderGraph: PropTypes.bool,
  graphs: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default GraphCards
