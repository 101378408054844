import * as React from 'react'

import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel'

import MessageKeys from '../../../languages/en/en.json'
import PanelContent from './panelContent'

// File TODO:
// 1. use board name to show links to appropriate product
//    (need definitely list of board names)

function SideHelpPanel () {
  return (
    <HelpPanel
      header={ MessageKeys.expressLink.sideHelpPanel.title }
    >
      <PanelContent/>
    </HelpPanel>
  )
}

export default SideHelpPanel
