import React from 'react'

import Alert from '@amzn/awsui-components-react/polaris/alert'
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout'
import Box from '@amzn/awsui-components-react/polaris/box'
import Container from '@amzn/awsui-components-react/polaris/container'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'

import MessageKeys from '../../../languages/en/en.json'
import {
  MAIN_APP_LAYOUT_CONTENT_TID
} from '../../../utils/componentTestIds'
import { SLOW_DATA_TIMEOUT_MS } from '../../../utils/constants'
import { authenticateWithCognito, createAndGetCognitoIdentity, getURLQueryParameters } from '../../../utils/utils'
import VisualizerGraph from '../../commons/visualizerGraph'
import BoardInfo from '../BoardInfo/boardInfo'
import SideHelpPanel from '../SideHelpPanel/sideHelpPanel'
import Title from '../Title/title'
import GraduateToAWS from '../Visualizer/graduateToAWS'
import GraphCards from '../Visualizer/graphCards'
import GraphDataFormatInfo from '../Visualizer/graphDataFormatInfo'
import SensorGraphTutorial from '../Visualizer/sensorGraphTutorial'

import '../../../css/expresslink/index.css'
import '@amzn/awsui-global-styles/polaris.css'

// File TODO:
// 1. remove log statements for exceptions, handle them better
// 2. add scrolling capability to invalid JSON error state

function Dashboard () {
  const urlParams = getURLQueryParameters()
  const [urlValid, setUrlValid] = React.useState(true)
  const [thingId, setThingId] = React.useState('')
  const [boardType, setBoardType] = React.useState('')
  const [demoExpired, setDemoExpired] = React.useState(false)
  const [boardConnectedBefore, setBoardConnectedBefore] = React.useState(false)
  const [slowDataError, setSlowDataError] = React.useState(false)
  const [JSONSyntaxError, setJSONSyntaxError] = React.useState(false)
  const [invalidJSON, setInvalidJSON] = React.useState({})
  const [latestData, setLatestData] = React.useState([])
  const [graphs, setGraphs] = React.useState(<></>)
  const [helpPanelVisible, setHelpPanelVisible] = React.useState(false)

  let lastDataTimestamp = Date.now()

  React.useEffect(() => {
    // check URL validity
    if (!urlParams.isValid) {
      setUrlValid(false)
      return
    }

    setThingId(urlParams.thingName)
    setBoardType(urlParams.boardName)

    // Authentication flow
    const client = createAndGetCognitoIdentity(urlParams.thingName)
    try {
      authenticateWithCognito(client)
    } catch (e) {
      console.err('Could not authenticate with Cognito: ' + e)
      if (e.code === 400) {
        setDemoExpired(false)
      }
    }
    // When connected, subscribe to topic
    client.on('connect', function () {
      client.subscribe(urlParams.thingName, function (err) {
        if (err) {
          console.log(`Could not subscribe to ${urlParams.thingName}: ` + err)
        }
      })
      client.subscribe(urlParams.thingName + '/+', function (err) {
        if (err) {
          console.log(`Could not subscribe to ${urlParams.thingName}/+: ` + err)
        }
      })
    })

    // On message, parse the JSON, update the graph list with alphabetical titles, update new data with the data
    // Also propagate that we are connected up to parent
    client.on('message', function (_topic, message) {
      try {
        setBoardConnectedBefore(true)
        setSlowDataError(false)
        lastDataTimestamp = Date.now()

        const parsed = JSON.parse(message)
        setLatestData(parsed)
      } catch (e) {
        console.log(e)
        setInvalidJSON(decodeURIComponent(message))
        setJSONSyntaxError(true)
      }
    })

    setInterval(() => checkForTimeout(), 1000)

    return () => {
      unsubscribeFromTopic(client)
    }
  }, [])

  React.useEffect(() => {
    updateGraphs()
  }, [latestData])

  function checkForTimeout () {
    if (slowDataError) return
    if (Date.now() - lastDataTimestamp > SLOW_DATA_TIMEOUT_MS) {
      setSlowDataError(true)
      setLatestData([])
    } else {
      setSlowDataError(false)
    }
  }

  function updateGraphs () {
    if (latestData.length !== 0) {
      const newGraphs = []
      try {
        if (slowDataError || demoExpired) {
          // If there is no real data coming, then remove the graphs and show the error
          setGraphs(newGraphs)
          return
        }

        // Parse out data that is not supposed to be displayed as a graph
        // TODO: may be a good idea to have a dedicated message handler that
        // verifies validity and propagates data for each type of visualization
        // by filtering like below, but this is post MVP
        const graphData = latestData.filter(obj => obj.display_type === 'line_graph')
        const numGraphs = graphData.length
        // Verify JSON validity.
        if (numGraphs === 0) {
          throw SyntaxError
        }

        for (let graphNum = 0; graphNum < numGraphs; ++graphNum) {
          const graphObj = graphData[graphNum]
          if (graphObj.label === undefined || typeof graphObj.label !== 'string' ||
              graphObj.values === undefined || !(graphObj.values instanceof Array) || typeof graphObj.values[0].unit !== 'string') {
            throw SyntaxError
          }

          const unit = graphObj.values[0].unit

          // Perform rounding on all values
          const values = graphObj.values.map((valueObj) => {
            if (isNaN(valueObj.value)) { throw SyntaxError }
            return Math.round((valueObj.value + Number.EPSILON) * 100) / 100
          })

          newGraphs.push(
            <Container key={graphObj.label}>
              <VisualizerGraph newData={values[0]} graphName={graphObj.label} unit={unit} graphColor={'#7993E0'} />
            </Container>
          )
        }
        setJSONSyntaxError(false)
        setGraphs(newGraphs)
      } catch (e) {
        console.log(e)
        setInvalidJSON(latestData)
        setJSONSyntaxError(true)
      }
    }
  }

  function toggleHelpPanel () {
    setHelpPanelVisible(true)
  }

  function unsubscribeFromTopic (client) {
    client.unsubscribe(thingId, function (err) {
      if (err) {
        console.log(err)
      }
    })
  }

  function getBoardStatus () {
    if (slowDataError) {
      return 'disconnected'
    } else if (!boardConnectedBefore) {
      return 'searching'
    } else { return 'connected' }
  }

  function renderDashboardContent () {
    if (!urlValid) {
      return (
        <Alert
          visible={true}
          type='error'
          header={ MessageKeys.instructionCards.errors.invalidParams.header }
        >
          { MessageKeys.instructionCards.errors.invalidParams.subheader }
        </Alert>
      )
    }

    return (
      <>
        <BoardInfo
          boardType={ boardType }
          thingId={ thingId }
          boardStatus={ getBoardStatus() }
          onInfoClick={ toggleHelpPanel }
        />
        {
          slowDataError
            ? (
              <Alert
                visible={ slowDataError }
                type='error'
                header={ MessageKeys.instructionCards.errors.slowData.header }
              >
                { MessageKeys.instructionCards.errors.slowData.subheader }
              </Alert>
            )
            : (<></>)
        }
        {
          JSONSyntaxError
            ? (
              <Alert
                visible={ JSONSyntaxError }
                type='error'
                header={ MessageKeys.instructionCards.errors.JSONError.header }
              >
                { MessageKeys.instructionCards.errors.JSONError.subheader }
                <br/>
                <Box variant='code'>
                  { JSON.stringify(invalidJSON, null, '  \n') }
                </Box>
              </Alert>
            )
            : (<></>)
        }
        {
          !(slowDataError || JSONSyntaxError)
            ? (
              <>
                <GraphDataFormatInfo visible={ urlValid && !slowDataError && !JSONSyntaxError }/>
                <GraphCards renderGraph={ latestData.length !== 0 } graphs={ graphs }/>
              </>
            )
            : (<></>)
        }
        {
          boardConnectedBefore
            ? (<>
              <SensorGraphTutorial/>
              <GraduateToAWS/>
            </>)
            : (<></>)
        }
      </>
    )
  }

  return (
    <AppLayout
      headerSelector='#h'
      footerSelector='#f'
      navigationHide
      content={
        <SpaceBetween
          direction='vertical'
          size='l'
          className='content'
          data-testid={ MAIN_APP_LAYOUT_CONTENT_TID }
        >
          <Title boardName={ boardType }/>
          { renderDashboardContent() }
        </SpaceBetween>
      }
      tools={
        <SideHelpPanel/>
      }
      toolsOpen={ helpPanelVisible }
      onToolsChange={ ({ detail }) => setHelpPanelVisible(detail.open) }
    />
  )
}

export default Dashboard
