import React from 'react'

import PropTypes from 'prop-types'

import DemoExpiredErrorCard from './demoExpired'
import InvalidParamsErrorCard from './invalidParamErrorCard'
import JSONErrorCard from './JSONErrorCard'
import SlowDataErrorCard from './slowDataErrorCard'

/*
 * This function keeps track of which errors should be shown at what point depending on what props are passed to it
 * FILE TODO: One day abstract the error messages better through one component
 */
function ErrorWrapper (props) {
  return (
    <>
      {(props.demoExpiredError) ? <tr><td><div className="fr-cards-section fr-homepage-bordered-section"><ul className="fr-cards-list"><li className="error-card"><DemoExpiredErrorCard/></li></ul></div></td></tr> : <></>}
      {(props.invalidParamsError) ? <tr><td><div className="fr-cards-section fr-homepage-bordered-section"><ul className="fr-cards-list"><li className="error-card"><InvalidParamsErrorCard/></li></ul></div></td></tr> : <></>}
      {(props.JSONSyntaxError && props.slowDataError) ? <tr><td><div className="fr-cards-section fr-homepage-bordered-section"><ul className="fr-cards-list"><li className="error-card"><JSONErrorCard message={props.invalidJSON}/></li><li className="error-card"><SlowDataErrorCard/></li></ul></div></td></tr> : <></>}
      {(props.JSONSyntaxError && !props.slowDataError) ? <tr><td><div className="fr-cards-section fr-homepage-bordered-section"><ul className="fr-cards-list"><li className="error-card"><JSONErrorCard message={props.invalidJSON}/></li></ul></div></td></tr> : <></>}
      {(!props.JSONSyntaxError && props.slowDataError) ? <tr><td><div className="fr-cards-section fr-homepage-bordered-section"><ul className="fr-cards-list"><li className="error-card"><SlowDataErrorCard/></li></ul></div></td></tr> : <></>}
    </>
  )
}

export default ErrorWrapper

ErrorWrapper.propTypes = {
  slowDataError: PropTypes.bool,
  JSONSyntaxError: PropTypes.bool,
  invalidParamsError: PropTypes.bool,
  invalidJSON: PropTypes.string,
  demoExpiredError: PropTypes.bool
}
