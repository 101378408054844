export const MAXIMUM_RECONNECT_TIME_MS = 8 * 1000

export const GRAPHS_PER_ROW = 2
export const MAX_NUM_GRAPHS = 10
export const SLOW_DATA_TIMEOUT_MS = 60 * 1000

// React does not support enums, so do them manually
export const NOT_STARTED = 0
export const IN_PROGRESS = 1
export const FINISHED = 2

// The number of entries that fit on one screen
export const MAX_ENTRIES_AT_ONCE = 15

// The number of entries that we reach before resetting our data array
// Need to clear our data array once in a while to prevent overflows
// TODO: adjust this as we get better idea of the time ranges for the graph
export const MAX_ENTRIES_BEFORE_RESET = MAX_ENTRIES_AT_ONCE * 5

// From testing, updating slightly more often than data comes in looks best
// Data comes in once per second
export const ADDING_DATA_INTERVAL_IN_MILLISECONDS = 800

export const NUM_DEFAULT_GRAPHS = 2
