import React from 'react'

import Landing from './components/quick-connect/Landing/landing'
import RightPane from './components/quick-connect/RightPane/rightPane'
import Footer from './freertosWebsite/html/footer'
import Header from './freertosWebsite/html/header'
import './css/quick-connect/index.css'

function QuickConnectMain () {
  return (
    <div>
      <Header/>
      <Landing/>
      <RightPane/>
      <Footer/>
    </div>
  )
}

export default QuickConnectMain
