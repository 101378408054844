import * as React from 'react'

import Box from '@amzn/awsui-components-react/polaris/box'
import PropTypes from 'prop-types'

function ValueWithLabel (props) {
  return (
    <>
      <Box variant='awsui-key-label'>{ props.label }</Box>
      <>{ props.children }</>
    </>
  )
}

ValueWithLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
}

export default ValueWithLabel
