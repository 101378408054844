import * as React from 'react'

import Alert from '@amzn/awsui-components-react/polaris/alert'
import Box from '@amzn/awsui-components-react/polaris/box'
import PropTypes from 'prop-types'

import MessageKeys from '../../../languages/en/en.json'

function GraphDataFormatInfo (props) {
  return (
    <Alert
      visible={ props.visible }
    >
      { MessageKeys.expressLink.instructionCards.graphDataFormatInfo.micro1 }
      <Box display="inline" variant="code">
        { '[ { "label": <title>, "display_type" : "line_graph", "values": [ { "unit" : <unit>, "value" : <val>, "label" : "" } ] } ]' }
      </Box>.
      { MessageKeys.expressLink.instructionCards.graphDataFormatInfo.micro2 }
    </Alert>
  )
}

GraphDataFormatInfo.propTypes = {
  visible: PropTypes.bool
}

export default GraphDataFormatInfo
