import * as React from 'react'

import Box from '@amzn/awsui-components-react/polaris/box'
import Button from '@amzn/awsui-components-react/polaris/button'
import Modal from '@amzn/awsui-components-react/polaris/modal'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'
import PropTypes from 'prop-types'

import MessageKeys from '../../../languages/en/en.json'
import {
  TROUBLESHOOTING_MODAL_CLOSE_BUTTON_TID,
  TROUBLESHOOTING_MODAL_STEP1_BOX_TID,
  TROUBLESHOOTING_MODAL_STEP2_BOX_TID,
  TROUBLESHOOTING_MODAL_STEP3_BOX_TID,
  TROUBLESHOOTING_MODAL_STEP4_BOX_TID
} from '../../../utils/componentTestIds'

// File TODO: style, technical text

function TroubleshootingModal (props) {
  return (
    <Modal
      onDismiss={() => props.setVisible(false)}
      visible={props.visible}
      closeAriaLabel='Close troubleshooting board modal'
      size='large'
      footer={
        <Box float='right'>
          <Button
            variant='primary'
            data-testid={ TROUBLESHOOTING_MODAL_CLOSE_BUTTON_TID }
            onClick={(event) => props.setVisible(false)}
          >
            { MessageKeys.global.commonText.close }
          </Button>
        </Box>
      }
      header={ MessageKeys.expressLink.troubleshootingModal.title }
    >
      <SpaceBetween direction='vertical' size='l'>
        <Box style='p'>
          { MessageKeys.expressLink.troubleshootingModal.intro }
        </Box>
        <Box style='p' data-testid={ TROUBLESHOOTING_MODAL_STEP1_BOX_TID }>
          <b>{ MessageKeys.global.stepNumbers.step1 }</b>
          { `${MessageKeys.expressLink.troubleshootingModal.steps.step1.micro1}${props.boardName}${MessageKeys.expressLink.troubleshootingModal.steps.step1.micro2}` }
        </Box>
        <Box style='p' data-testid={ TROUBLESHOOTING_MODAL_STEP2_BOX_TID }>
          <b>{ MessageKeys.global.stepNumbers.step2 }</b>
          { `${MessageKeys.expressLink.troubleshootingModal.steps.step2.micro1}${props.boardName}${MessageKeys.expressLink.troubleshootingModal.steps.step2.micro2}` }
        </Box>
        <Box style='p' data-testid={ TROUBLESHOOTING_MODAL_STEP3_BOX_TID }>
          <b>{ MessageKeys.global.stepNumbers.step3 }</b>
          { MessageKeys.expressLink.troubleshootingModal.steps.step3.micro1 }
        </Box>
        <Box style='p' data-testid={ TROUBLESHOOTING_MODAL_STEP4_BOX_TID }>
          <b>{ MessageKeys.global.stepNumbers.step4 }</b>
          { MessageKeys.expressLink.troubleshootingModal.steps.step4.micro1 }
          <Box display='inline' variant='code'>{ MessageKeys.expressLink.troubleshootingModal.steps.step3.micro2 }</Box>
          { MessageKeys.expressLink.troubleshootingModal.steps.step4.micro3 }
        </Box>
      </SpaceBetween>
    </Modal>
  )
}

TroubleshootingModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  boardName: PropTypes.string
}

export default TroubleshootingModal
