import React, { useState, useEffect } from 'react'

import MessageKeys from '../../../languages/en/en.json'
import Configurations from '../Configurations/configurations'
import Tabs from '../Tabs/tabs'
import Title from '../Title/title'
import Visualizer from '../Visualizer/mqttGraph'

/*
 * This function is the wrapper for the title section and the selectable tabs
 * FILE TODO: See if we can maintain graph in background while on config
 */
function Landing () {
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
  }, [connected])

  return (
    <>
      <Title connected={connected} />
      <Tabs>
        <div label={MessageKeys.tabNames.visualizer} test-id="visualizer-tab">
          <Visualizer setConnected={setConnected}/>
        </div>
        <div label={MessageKeys.tabNames.configuration} test-id="configuration-tab">
          <Configurations/>
        </div>
      </Tabs>
    </>
  )
}

export default Landing
