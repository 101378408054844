import React, { useState } from 'react'

import PropTypes from 'prop-types'

import MessageKeys from '../../../../languages/en/en.json'
import { NOT_STARTED, IN_PROGRESS } from '../../../../utils/constants'
import { getURLQueryParameters } from '../../../../utils/utils'
import SensorGraphDefault from './sensorGraphDefault'
import SensorGraphTutorial from './sensorGraphTutorial'

/*
 * This function manages where the Tutorial and the Default card are on the page
 * FILE TODO: Need to find a way to remove the <hr> as it is not supposed to appear in the table
 */
function TutorialWrapper () {
  const [sensorGraph, updateSensorGraphWorkflow] = useState(NOT_STARTED)
  const params = getURLQueryParameters()

  return (
    <>
      {(sensorGraph === IN_PROGRESS) ? <SensorGraphTutorial deviceName={params.boardName} updateWorkflow={updateSensorGraphWorkflow} /> : <></>}
      <hr/><tr/>
      {(sensorGraph === NOT_STARTED) ? <SensorGraphDefault deviceName={params.boardName} updateWorkflow={updateSensorGraphWorkflow}/> : <></>}

      <tr>
        <td colSpan='2'>
          <div className="fr-cards-section fr-homepage-bordered-section">
            <ul className="fr-cards-list">
              <li>
                <h3 className="fr-section-subtitle">{MessageKeys.instructionCards.graduation.header}</h3>
                <p> {MessageKeys.instructionCards.graduation.description} </p>
                <a href="https://docs.aws.amazon.com/freertos/latest/userguide/freertos-getting-started.html" target="_blank" rel="noreferrer">
                  <div className='fr-button fr-variant-secondary' test-id='graduate-button'>
                    {MessageKeys.instructionCards.graduation.cta}
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </>
  )
}

export default TutorialWrapper

TutorialWrapper.propTypes = {
  numGraphs: PropTypes.number
}
