import React from 'react'

import '../../../../freertosWebsite/css/card.css'

import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'

import MessageKeys from '../../../../languages/en/en.json'

/*
 * This function returns the error card when the demo has expired.
 */
function DemoExpiredErrorCard () {
  return (
    <div>
      <StatusIndicator className='icon' type='warning'/>
      <div className="error-card-wrapper">
        <h3 className="fr-section-subtitle">{MessageKeys.instructionCards.errors.demoExpired.header}</h3>
        <p className="error-card-text"> {MessageKeys.instructionCards.errors.demoExpired.subheader} </p>
      </div>
    </div>
  )
}

export default DemoExpiredErrorCard
