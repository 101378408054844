import * as React from 'react'

import Icon from '@amzn/awsui-components-react/polaris/icon'
import Link from '@amzn/awsui-components-react/polaris/link'

import MessageKeys from '../../../languages/en/en.json'
import '../../../css/expresslink/helpPanel.css'

// File TODO: Finalize what is in the help panel content
// right now it is the same as quick connect; we might want
// to have expresslink-specific links in the content.

function PanelContent (props) {
  return (
    <>
      <h3>{ MessageKeys.expressLink.sideHelpPanel.content.learnMore } <Icon name='external' /></h3>
      <ul className='no-indent-list'>
        <li>
          <Link
            href='https://devices.amazonaws.com/search?page=1&sv=iotxplnk'
            target='_blank'
          >{ MessageKeys.expressLink.sideHelpPanel.content.links.common.allExpressLinkProducts }
          </Link>
        </li>
        <li>
          <Link
            href='https://docs.aws.amazon.com/iot-expresslink/latest/programmersguide/elpg.html'
            target='_blank'
          >
            { MessageKeys.expressLink.sideHelpPanel.content.links.common.awsTutorial }
          </Link>
        </li>
      </ul>
    </>
  )
}

export default PanelContent
