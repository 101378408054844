/* Dashboard test ids */
export const MAIN_APP_LAYOUT_CONTENT_TID = 'tid-main-app-layout-content-column-layout'

/* Title test ids */
export const COPY_DASHBOARD_URL_BUTTON_TID = 'tid-copy-dashboard-url-button'
export const COPY_DASHBOARD_URL_POPOVER_CONTENT_TID = 'tid-copy-dashboard-url-popover-content'

/* BoardInfo test ids */
export const BOARD_OVERVIEW_INFO_LINK_TID = 'tid-board-overview-info-link'
export const TROUBLESHOOTING_BUTTON_TID = 'tid-troubleshooting-button'
export const BOARD_IMAGE_TID = 'tid-board-image'

/* TroubleshootingModal test ids */
export const TROUBLESHOOTING_MODAL_CLOSE_BUTTON_TID = 'tid-troubleshooting-modal-close-button'
export const TROUBLESHOOTING_MODAL_STEP1_BOX_TID = 'tid-troubleshooting-modal-step1-box'
export const TROUBLESHOOTING_MODAL_STEP2_BOX_TID = 'tid-troubleshooting-modal-step2-box'
export const TROUBLESHOOTING_MODAL_STEP3_BOX_TID = 'tid-troubleshooting-modal-step3-box'
export const TROUBLESHOOTING_MODAL_STEP4_BOX_TID = 'tid-troubleshooting-modal-step4-box'

/* SensorGraphTutorial test ids */
export const HOW_TO_ADD_A_GRAPH_SECTION_TID = 'tid-how-to-add-a-graph-section'

/* GraduateToAWS test ids */
export const CONNECT_TO_AWS_BUTTON_TID = 'tid-connect-to-aws-button'
