import React from 'react'

import '../css/footer.css'

/* This file's content was taken from freertos.org */
function Footer () {
  return (
    <>
      <div align="center">
        <div className="menu-footer-container">
          <footer className="footer-menu">
            <a href="#page_top">[ Back to the top ]</a>
            &nbsp;&nbsp;<a href="https://freertos.org/RTOS.html" target="_blank" rel="noopener noreferrer">[ About FreeRTOS ]</a>
            &nbsp;&nbsp;<a href="https://freertos.org/privacy.html" target="_blank" rel="noopener noreferrer">[ Privacy ]</a>
            &nbsp;&nbsp;<a href="https://freertos.org/static_menu.html" target="_blank" rel="noopener noreferrer">[ Sitemap ]</a>
            &nbsp;&nbsp;<a href="mailto:info@freertos.org" target="" rel="noopener noreferrer">[ Report an error on this page ]</a>
          </footer>
        </div>
      </div>
      <div align="center" className="normal_text">
        <font size="1"><div align="center">Copyright (C) Amazon Web Services, Inc. or its affiliates. All rights reserved.</div></font>
      </div>
    </>
  )
}

export default Footer
