import React from 'react'

import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'

/*
 * This file contains the configuration for apexcharts graph and calls the utility to create the graph
 */
function RealtimeLineChart (props) {
  const options = {

    stroke: {
      // Options are smooth, straight, or stepline
      curve: 'smooth'
    },

    title: {
      text: `${props.graphName}`,
      align: 'left',
      style: {
        fontSize: '22px',
        fontFamily: 'AmazonEmber',
        fontWeight: 'bold'
      }
    },
    xaxis: {
      type: 'datetime',
      range: props.range,
      labels: {
        datetimeFormatter: {
          day: 'dd MMM',
          hour: 'HH:mm:ss'
        }
      },
      title: {
        text: 'Time',
        offsetX: -20,
        offsetY: 5,
        style: {
          fontFamily: 'AmazonEmber'
        }
      }
    },
    yaxis: {
      title: {
        text: `${props.unit}`
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    chart: {
      id: 'realtime',
      type: 'line',
      animations: {
        enabled: true,

        // Options are linear, easein, easeout, easeinout
        easing: 'easeinout',
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [props.graphColor]
  }

  return <Chart type="line" options={options} series={props.dataList} />
}

export default RealtimeLineChart

RealtimeLineChart.propTypes = {
  graphColor: PropTypes.string,
  graphName: PropTypes.string,
  dataList: PropTypes.array,
  range: PropTypes.number,
  unit: PropTypes.string
}
