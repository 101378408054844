import React from 'react'

import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'
import PropTypes from 'prop-types'

import '../../../../freertosWebsite/css/card.css'

import MessageKeys from '../../../../languages/en/en.json'

/*
 * This function returns the error card for when the JSON given is invalid, whether because of syntax or format
 */
function JSONErrorCard (props) {
  return (
    <div>
      <StatusIndicator className='icon' type='warning'/>
      <div className="error-card-wrapper">
        <h3 className="fr-section-subtitle">{MessageKeys.instructionCards.errors.JSONError.header}</h3>
        <p className="error-card-text"> {MessageKeys.instructionCards.errors.JSONError.subheader} </p>
        <div className="code-wrapper">
          <code>
            {props.message}
          </code>
        </div>
      </div>
    </div>
  )
}

export default JSONErrorCard

JSONErrorCard.propTypes =
{
  message: PropTypes.string
}
