import React from 'react'

import ExpressLinkMain from './expresslinkMain'
import QuickConnectMain from './quickConnectMain'

import './css/quick-connect/index.css'

// File TODO: more rigorous check of expresslink vs quick connect

function Main () {
  return (
    <main>
      { window.location.href.includes('localhost') || window.location.href.includes('expresslink')
        ? <ExpressLinkMain/>
        : <QuickConnectMain/> }
    </main>
  )
}

export default Main
