import React from 'react'

import '../../../freertosWebsite/css/button.css'
import '../../../css/quick-connect/rightPane.css'

import Button from '@amzn/awsui-components-react/polaris/button'
import Popover from '@amzn/awsui-components-react/polaris/popover'
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'

import MessageKeys from '../../../languages/en/en.json'
import { getTokensAndCredsByStage } from '../../../utils/utils'

/*
 * This function controls the control flow on the right side of the page
 * This includes a share button and links to different resources for the customer
 * FILE TODO: Popover is left at the moment. Abstract the strings
 */
function RightPane () {
  const VendorTemplate = getTokensAndCredsByStage()[1]

  function copyLink () {
    navigator.clipboard.writeText(window.location.href)
  }

  return (
    <div className='rightPane'>
      <div className='sticky'>
        <Popover
          dismissButton={false}
          position="left"
          size="small"
          triggerType="custom"
          className='popover'
          content={
            <StatusIndicator type="success" >
              Copied URL!
            </StatusIndicator>
          }
        >
          <Button className='share-button' iconName="copy" variant="normal" onClick={ () => copyLink()}> {MessageKeys.rightPane.share} </Button>
        </Popover>
        <hr/>
        <ul className='links'>
          {VendorTemplate.vendorName === 'ST'
            ? <> <li><a href={VendorTemplate.productPage} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksST.productPage}</a></li>
              <li><a href={VendorTemplate.awsTutorial} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksST.awsTutorial}</a></li></>
            : <><li><a href={VendorTemplate.productPage} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksESP.productPage}</a></li>
              <li><a href={VendorTemplate.awsTutorial} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksESP.awsTutorial}</a></li></>}

        </ul>
        <ul className='links'>
          <li><b>Developer Tools</b></li>
          {VendorTemplate.vendorName === 'ST'
            ? <><li><a href={VendorTemplate.ideUrl} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksST.STM32CubeIDE}</a></li>
              <li><a href={VendorTemplate.STM32CubeProgrammer} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksST.STM32CubeProgrammer}</a></li>
              <li><a href={VendorTemplate.deviceCode} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksST.deviceCode}</a></li></>
            : VendorTemplate.vendorName === 'ESP'
              ? <><li><a href={VendorTemplate.ideUrl} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksESP.mcuIDE}</a></li>
                <li><a href={VendorTemplate.deviceCode} target='_blank' rel='noopener noreferrer'>{MessageKeys.rightPane.linksESP.deviceCode}</a></li></>
              : <></>
          }

        </ul>
      </div>
    </div>
  )
}

export default RightPane
