import React from 'react'

import '../css/header.css'
import '../css/button.css'

/* This file's content was taken from freertos.org */
function Header () {
  return (
    <div id="main_top_header">

      <div id="afr_page_header_section">

        <div id="afr_page_header">
          <a href="https://freertos.org/index.html" className="freertos_logo">
            <img src="/images/fr-logo.jpeg" alt="FreeRTOS logo" border="0"/>
          </a>
          <div id="afr_top_nav">
            <div className="menu-subheader-dropdown-container">
              <ul id="afr_subheader_dropdown">
                <li className="afr_nav_item">
                  <a className="afr_nav_has_children" href="https://freertos.org/RTOS.html" target="">Kernel</a>
                  <ul className="afr_subheader_dropdown_submenu">
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-quick-start-guide.html" target="">Getting Started</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/RTOS.html" target="">About FreeRTOS Kernel</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/features.html" target="">Developer Docs</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/kernel/secondarydocs.html" target="">Secondary Docs</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/RTOS_ports.html" target="">Supported Devices</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/a00106.html" target="">API Reference</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/kernel/license.html" target="">Licensing</a></li>
                    <li className="afr_submenu_item">
                      <a className="afr_nav_has_children" href="#" target="">Emulation/Simulation</a>
                      <span className="awsui-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                          <path className="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"></path>
                        </svg>
                      </span>
                      <ul className="afr_subheader_dropdown_submenu">
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Windows-Simulator-Emulator-for-Visual-Studio-and-Eclipse-MingW.html" target="">Windows Simulator</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-simulator-for-Linux.html" target="">Posix/Linux Simulator</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="afr_nav_item">
                  <a className="afr_nav_has_children" href="https://freertos.org/libraries/categories.html" target="">Libraries</a>
                  <ul className="afr_subheader_dropdown_submenu">
                    <li className="afr_submenu_item afr_lts_libraries_submenu_item"><a className="" href="https://freertos.org/lts-libraries.html" target=""><span className="new_icon_wrp"><span className="new_icon">new</span></span>LTS Libraries</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/libraries/categories.html" target="">Libraries Categories</a></li>
                    <li className="afr_submenu_item">
                      <a className="afr_nav_has_children" href="https://freertos.org/FreeRTOS-Plus/index.html" target="">FreeRTOS Libraries</a>
                      <span className="awsui-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                          <path className="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"></path>
                        </svg>
                      </span>
                      <ul className="afr_subheader_dropdown_submenu">
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/index.html" target="">Overview</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_TCP/index.html" target="">FreeRTOS+TCP</a></li>
                        <li className="afr_submenu_item">
                          <a className="afr_nav_has_children" href="https://freertos.org/application-protocols.html" target="">Application Protocols</a>
                          <span className="awsui-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                              <path className="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"></path>
                            </svg>
                          </span>
                          <ul className="afr_subheader_dropdown_submenu">
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/application-protocols.html" target="">Overview</a></li>
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/mqtt/index.html" target="">coreMQTT</a></li>
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/mqtt-agent/index.html" target="">coreMQTT Agent</a></li>
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/http/index.html" target="">coreHTTP</a></li>
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/coresntp/index.html" target="">coreSNTP</a></li>
                            <li className="afr_submenu_item"><a className="" href="https://freertos.org/network-interface.html" target="">Transport Interface</a></li>
                          </ul>
                        </li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/json/index.html" target="">coreJSON</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/pkcs11/index.html" target="">corePKCS11</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_IO/FreeRTOS_Plus_IO.html" target="">FreeRTOS+IO</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_CLI/FreeRTOS_Plus_Command_Line_Interface.html" target="">FreeRTOS+CLI</a></li>
                      </ul>
                    </li>
                    <li className="afr_submenu_item">
                      <a className="afr_nav_has_children" href="https://freertos.org/iot-libraries.html" target="">AWS IoT Libraries</a>
                      <span className="awsui-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                          <path className="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"></path>
                        </svg>
                      </span>
                      <ul className="afr_subheader_dropdown_submenu">
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/iot-libraries.html" target="">Overview</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/aws-reference-integrations.html" target="">Reference Integrations</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/ota/index.html" target="">AWS IoT OTA</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/iot-device-shadow/index.html" target="">AWS IoT Device Shadow</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/iot-jobs/index.html" target="">AWS IoT Jobs</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/iot-device-defender/index.html" target="">AWS IoT Device Defender</a></li>
                      </ul>
                    </li>
                    <li className="afr_submenu_item">
                      <a className="afr_nav_has_children" href="https://freertos.org/FreeRTOS-Labs/index.html" target="">FreeRTOS Labs</a>
                      <span className="awsui-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
                          <path className="filled stroke-linejoin-round" d="M4 5h8l-4 6-4-6z"></path>
                        </svg>
                      </span>
                      <ul className="afr_subheader_dropdown_submenu">
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Labs/index.html" target="">Overview</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/cellular/index.html" target="">FreeRTOS Cellular Interface</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/lorawan/index.html" target="">LoRaWAN</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_POSIX/index.html" target="">FreeRTOS+POSIX</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_FAT/index.html" target="">FreeRTOS+FAT</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_TCP/IPv6/index.html" target="">FreeRTOS+TCP IPv6</a></li>
                        <li className="afr_submenu_item"><a className="" href="https://freertos.org/FreeRTOS-Plus/FreeRTOS_Plus_TCP/freertostcp-multiple-interfaces.html" target="">FreeRTOS+TCP Multiple Interfaces</a></li>
                      </ul>
                    </li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/libraries/license.html" target="">Licensing</a></li>
                  </ul>
                </li>
                <li className="afr_nav_item">
                  <a className="afr_nav_has_children" href="https://freertos.org/FAQ.html" target="">Support</a>
                  <ul className="afr_subheader_dropdown_submenu">
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/contact.html" target="">Contact</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/RTOS-contact-and-support.html" target="">Support Options</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/RTOS-contact-and-support.html#mailing_list" target="">Mailing List</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/a00114.html" target="">Licensing</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/FAQ.html" target="">FAQ</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/Documentation/RTOS_book.html" target="">Books and Manuals</a></li>
                  </ul>
                </li>
                <li className="afr_nav_item">
                  <a className="afr_nav_has_children" href="https://freertos.org/partners/overview.html" target="">Partners</a>
                  <ul className="afr_subheader_dropdown_submenu">
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/partners/overview.html" target="">Overview</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/partners/semiconductor.html" target="">Semiconductor</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/partners/dev-tools-and-kits.html" target="">Productivity Tools</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/partners/training-and-consulting.html" target="">Training and Consulting</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/partners/software.html" target="">Software</a></li>
                  </ul>
                </li>
                <li className="afr_nav_item">
                  <a className="afr_nav_has_children" href="https://freertos.org/community/overview.html" target="">Community</a>
                  <ul className="afr_subheader_dropdown_submenu">
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/community/overview.html" target="">Overview</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://forums.freertos.org/" target="_blank" rel="noopener noreferrer">Forums</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://freertos.org/blog.html" target="">Blog</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://forums.freertos.org/c/community-media/" target="">Media</a></li>
                    <li className="afr_submenu_item"><a className="" href="https://twitter.com/intent/follow?screen_name=real_FreeRTOS" target="_blank" rel="noopener noreferrer">FreeRTOS on Twitter</a></li>
                  </ul>
                </li>
              </ul>
            </div>
            <a className="main_top_header_cta" href="https://freertos.org/a00104.html">
              <span className="fr-button fr-variant-primary fr-cta">
                <span className="fr-button-label">Download FreeRTOS</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
