import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'

import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'

import Main from './Main'

if (window.fetch) {
  // Check whether ES6 is supported in Modern Browsers
  import('./Main').then(function (module) {
    module.default()
  })
} else {
  // For legacy or old browsers
  import('./polyfills').then(() => {
    import('./Main').then(function (module) {
      module.default()
    })
  })
}

const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project'
  }
}

if (window.location.href.includes('st.')) {
  tagManagerArgs.gtmId = 'GTM-NW23H8S'
  TagManager.initialize(tagManagerArgs)
} else if (window.location.href.includes('esp.')) {
  tagManagerArgs.gtmId = 'GTM-MRCPSJ8'
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render((
  <Main />
), document.getElementById('root')
)
