import React from 'react'

import PropTypes from 'prop-types'

import '../../../css/quick-connect/tabs.css'
import '../../../css/quick-connect/visualizer.css'

import { GRAPHS_PER_ROW, MAX_NUM_GRAPHS, SLOW_DATA_TIMEOUT_MS } from '../../../utils/constants'
import { authenticateWithCognito, createAndGetCognitoIdentity, getURLQueryParameters } from '../../../utils/utils'
import VisualizerGraph from '../../commons/visualizerGraph'
import InstructionCards from '../InstructionCards/instructionCards'

/*
 * This class maintains the mqtt connection for the graphs.
 * It also calls children (visualizerGraph) to maintain the graph itself.
 * File TODO: Localize text.
 */

class MqttGraph extends React.Component {
  constructor (props) {
    super()
    this.state = {
      newData: {},

      params: getURLQueryParameters(),

      rows: [],

      invalidParamsError: !getURLQueryParameters().isValid,

      demoExpiredError: false,

      JSONSyntaxError: false,

      invalidJSON: '',

      slowDataError: false,

      lastTimestamp: Date.now()
    }
  }

  componentDidMount () {
    // Authentication flow

    const client = createAndGetCognitoIdentity(this.state.params.thingName)
    try {
      authenticateWithCognito(client)
    } catch (e) {
      console.log(e)

      if (e.code === 400) {
        this.setState({ demoExpiredError: true })
      }
    }

    // When connected, subscribe to topic
    client.on('connect', function () {
      client.subscribe(this.state.params.thingName + '/+', function (err) {
        if (err) {
          console.log(err)
        }
      })
      client.subscribe(this.state.params.thingName, function (err) {
        if (err) {
          console.log(err)
        }
      })
    }.bind(this))

    // On message, parse the JSON, update the graph list with alphabetical titles, update new data with the data
    // Also propagate that we are connected up to parent
    client.on('message', function (_topic, message) {
      try {
        this.props.setConnected(true)
        this.setState({ slowDataError: false, lastTimestamp: Date.now() })

        const parsed = JSON.parse(message)
        this.setState({ newData: parsed }, this.updateGraphs)
      } catch (e) {
        this.setState({ invalidJSON: decodeURIComponent(message), JSONSyntaxError: true, rows: [] })
      }
    }.bind(this))

    this.unsubscribeFromTopic = function () {
      const tempTopicName = this.state.topicName
      client.unsubscribe(tempTopicName, function (err) {
        if (err) {
          console.log(err)
        }
      })
    }

    this.updateGraphs = () => {
      let rows = []
      if (this.state.newData.length !== 0) {
        try {
          if (this.state.slowDataError || this.state.demoExpiredError) {
            // If there is no real data coming, then remove the graphs and show the error
            this.setState({ rows: rows })
            return
          }

          // Parse out data that is not supposed to be displayed as a graph
          // TODO: may be a good idea to have a dedicated message handler that
          // verifies validity and propagates data for each type of visualization
          // by filtering like below, but this is post MVP
          const graphData = this.state.newData.filter(obj => obj.display_type === 'line_graph')
          const numGraphs = graphData.length
          // Verify JSON validity.
          if (numGraphs === 0) {
            throw SyntaxError
          }

          // Loop through the data we have and arrange them in rows of GRAPHS_PER_ROW, up to MAX_NUM_GRAPHS total graphs.
          for (let rowNum = 0; rowNum < Math.min(numGraphs, MAX_NUM_GRAPHS) / 2; rowNum++) {
            const rowID = `row${rowNum}`
            const cell = []

            for (let graphNum = rowNum * 2; graphNum < (rowNum * 2 + GRAPHS_PER_ROW) && (graphNum < numGraphs); graphNum++) {
              // TODO: Currently the visualizer only supports plotting one line
              // on each graph, thus, values[0] is used in places,
              // but this will need to be updated at some point

              // Verify JSON validity.
              const graphObj = graphData[graphNum]
              if (graphObj.label === undefined || typeof graphObj.label !== 'string' ||
                graphObj.values === undefined || !(graphObj.values instanceof Array) || typeof graphObj.values[0].unit !== 'string') {
                throw SyntaxError
              }

              const unit = graphObj.values[0].unit

              // Perform rounding on all values
              const values = graphObj.values.map((valueObj) => {
                if (isNaN(valueObj.value)) { throw SyntaxError }
                return Math.round((valueObj.value + Number.EPSILON) * 100) / 100
              })

              cell.push(<td key={graphObj.label} className='graph'><VisualizerGraph newData={values[0]} graphName={graphObj.label} unit={unit} graphColor={'#247133'} /></td>)
            }
            rows.push(<tr key={rowNum} id={rowID}>{cell}</tr>)
          }
          this.setState({ invalidJSON: '', JSONSyntaxError: false })
        } catch (e) {
          this.setState({ invalidJSON: JSON.stringify(this.state.newData), JSONSyntaxError: true })
          rows = []
        }
      }
      this.setState({ rows: rows })
    }

    this.checkForTimeout = function () {
      if (this.state.slowDataError) return
      if (Date.now() - this.state.lastTimestamp > SLOW_DATA_TIMEOUT_MS) {
        this.setState({ slowDataError: true }, this.updateGraphs)
        this.props.setConnected(false)
      }
    }
    this.timeoutInterval = setInterval(() => this.checkForTimeout(), 1000)
  }

  componentWillUnmount () {
    clearInterval(this.timeoutInterval)
  }

  render () {
    return (
      <>
        { this.state.rows.length > 0
          ? <> <p>
          These graphs show the values sent to AWS as key-value pairs published via MQTT. Each key-value pair is formatted as:</p>
          <code className='sample'> {'[ { "label": <title>, "display_type" : "line_graph", "values": [ { "unit" : <unit>, "value" : <val>, "label" : "" } ] } ]'} </code> <br/>
          <p> This dashboard displays the &lt;title&gt; as the title of the graph, the &lt;unit&gt; as the label for the y-axis, and the &lt;val&gt; is plotted on the graph as a point in time when it was published. The display_type and label portions of the input will be used for future upgrades. The graphs can visualize values published at a frequency between 1 and 60 seconds.
          </p> </>
          : <></>
        }

        <div className="tab-body-graph">
          <table className ="graphs">
            <tbody>
              {this.state.rows}
            </tbody>
          </table>
          <table className ="graphs">
            <tbody>
              <InstructionCards slowDataError={this.state.slowDataError} JSONSyntaxError={this.state.JSONSyntaxError} invalidParamsError={this.state.invalidParamsError} invalidJSON={this.state.invalidJSON} demoExpiredError={this.state.demoExpiredError} numGraphs={Object.keys(this.state.newData).length}/>
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default MqttGraph

MqttGraph.propTypes = {
  setConnected: PropTypes.func
}
