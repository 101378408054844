import React from 'react'

import PropTypes from 'prop-types'

import ErrorWrapper from './Errors/errorWrapper'
import TutorialWrapper from './Tutorials/tutorialWrapper'

import '../../../freertosWebsite/css/card.css'
import '../../../css/quick-connect/configurationForm.css'

/*
 * This function returns the grid of instruction cards in the following order
 *     Errors, if there are any
 *     Any active tutorial flows
 *     The default cards to start the workflow
 *     The graduate to AWS card
 * FILE TODO: Graduate button when claim API is done
 */
function InstructionCards (props) {
  return (
    <>
      <ErrorWrapper JSONSyntaxError={props.JSONSyntaxError} slowDataError={props.slowDataError} demoExpiredError={props.demoExpiredError} invalidParamsError={props.invalidParamsError} invalidJSON={props.invalidJSON}/>
      {props.numGraphs > 0 ? <TutorialWrapper cardsActive={props.numGraphs}/> : <></>}
    </>
  )
}

export default InstructionCards

InstructionCards.propTypes = {
  slowDataError: PropTypes.bool,
  JSONSyntaxError: PropTypes.bool,
  invalidParamsError: PropTypes.bool,
  invalidJSON: PropTypes.string,
  demoExpiredError: PropTypes.bool,
  numGraphs: PropTypes.number
}
