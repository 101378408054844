import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { ADDING_DATA_INTERVAL_IN_MILLISECONDS, MAX_ENTRIES_BEFORE_RESET, MAX_ENTRIES_AT_ONCE } from '../../utils/constants'
import RealtimeLineChart from './realtimeLineChart'

/*
 * This function maintains adding data to the graphs.
 * It also calls realTimeLineChart, which is the ApexCharts function to actually make the graph
 */
function VisualizerGraph (props) {
  // The value of the next entry to add to the graph
  const [nextVal, setNextVal] = useState(props.newData)

  const nameList = [props.graphName]

  // An array of maps of name to data
  const defaultDataList = nameList.map(name => ({
    name: name,
    data: [{ x: new Date(), y: 0 }]
  }))
  const [dataList, setDataList] = React.useState(defaultDataList)

  React.useEffect(() => {
    const addNewData = () => {
      setNextVal(props.newData)

      if (dataList[0].data.length >= MAX_ENTRIES_BEFORE_RESET) {
        // Reset to max entries at once
        dataList[0].data = dataList[0].data.slice(
          dataList[0].data.length - MAX_ENTRIES_AT_ONCE, dataList[0].data.length
        )
      }

      const curData = dataList[0].data
      return [...curData, { x: new Date(), y: nextVal }]
    }

    const interval = setInterval(() => {
      setDataList(
        dataList.map(val => {
          return {
            name: val.name,
            data: addNewData(val.data)
          }
        })
      )
    }, ADDING_DATA_INTERVAL_IN_MILLISECONDS)

    return () => clearInterval(interval)
  })

  return (
    <div >
      <RealtimeLineChart graphName={props.graphName} graphColor={props.graphColor} dataList={dataList} unit={props.unit} range={MAX_ENTRIES_AT_ONCE * 1000} />
    </div>
  )
}

export default VisualizerGraph

VisualizerGraph.propTypes = {
  graphColor: PropTypes.string,
  graphName: PropTypes.string,
  newData: PropTypes.number,
  unit: PropTypes.string
}
