import * as React from 'react'

import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation'

import MessageKeys from '../../../languages/en/en.json'

function ExpressLinkHeader () {
  return (
    <TopNavigation
      id='h'
      className='header'
      identity={{
        href: '',
        title: MessageKeys.expressLink.header.title,
        logo: {
          src: '/images/AWS-IoT-ExpressLink_64_White.svg',
          alt: 'Service'
        }
      }}
      i18nStrings={{
        overflowMenuTriggerText: ''
      }}
    />
  )
}

export default ExpressLinkHeader
