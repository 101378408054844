import * as React from 'react'

import Button from '@amzn/awsui-components-react/polaris/button'
import Container from '@amzn/awsui-components-react/polaris/container'
import Header from '@amzn/awsui-components-react/polaris/header'
import Link from '@amzn/awsui-components-react/polaris/link'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'
import PropTypes from 'prop-types'

import MessageKeys from '../../../languages/en/en.json'
import {
  BOARD_OVERVIEW_INFO_LINK_TID,
  TROUBLESHOOTING_BUTTON_TID,
  BOARD_IMAGE_TID
} from '../../../utils/componentTestIds'
import { getImageSrcFromBoardName } from '../../../utils/utils'
import TroubleshootingModal from './troubleshootingModal'
import ValueWithLabel from './valueWithLabel'

import '../../../css/expresslink/boardInfo.css'

// File TODO: correct board image

function BoardInfo (props) {
  const [modalVisible, showModal] = React.useState(false)

  function renderBoardStatus () {
    if (props.boardStatus === 'searching') {
      return (
        <StatusIndicator type='loading'>
          { MessageKeys.global.commonText[props.boardStatus] }
        </StatusIndicator>
      )
    } else if (props.boardStatus === 'connected') {
      return (
        <StatusIndicator>
          { MessageKeys.global.commonText[props.boardStatus] }
        </StatusIndicator>
      )
    } else { // props.boardStatus === 'disconnected'
      return (
        <StatusIndicator type='error'>
          { MessageKeys.global.commonText[props.boardStatus] }
        </StatusIndicator>
      )
    }
  }

  return (
    <>
      <TroubleshootingModal
        visible={ modalVisible }
        setVisible={ (isVisible) => showModal(isVisible) }
        boardName={ props.boardType }
      />
      <Container
        header={
          <Header
            variant="h2"
            info={
              <Link
                variant="info"
                onFollow={(detail) => { props.onInfoClick() } }
                data-testid={ BOARD_OVERVIEW_INFO_LINK_TID }
              >
                Info
              </Link>
            }
            actions={
              <Button
                data-testid={ TROUBLESHOOTING_BUTTON_TID }
                onClick={(event) => { showModal(true) }}
              >{ MessageKeys.expressLink.instructionCards.overview.troubleshootingButtonText }</Button>
            }
          >
            { MessageKeys.expressLink.instructionCards.overview.title }
          </Header>
        }
      >
        <SpaceBetween size='l' direction='horizontal'>
          <img
            className='board-image'
            src= { getImageSrcFromBoardName(props.boardType) }
            alt='Board Picture'
            data-testid={ BOARD_IMAGE_TID }
          />
          <SpaceBetween size='xxl'>
            <ValueWithLabel label={ MessageKeys.expressLink.instructionCards.overview.labels.boardType }>
              { props.boardType }
            </ValueWithLabel>
            <ValueWithLabel label={ MessageKeys.expressLink.instructionCards.overview.labels.thingId }>
              { props.thingId }
            </ValueWithLabel>
            <ValueWithLabel label={ MessageKeys.expressLink.instructionCards.overview.labels.status }>
              { renderBoardStatus() }
            </ValueWithLabel>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </>
  )
}

BoardInfo.propTypes = {
  onInfoClick: PropTypes.func,
  boardType: PropTypes.string,
  thingId: PropTypes.string,
  boardStatus: PropTypes.oneOf(['searching', 'connected', 'disconnected'])
}

export default BoardInfo
