import * as React from 'react'

import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section'
import Header from '@amzn/awsui-components-react/polaris/header'
import PropTypes from 'prop-types'

import MessageKeys from '../../../languages/en/en.json'
import {
  HOW_TO_ADD_A_GRAPH_SECTION_TID
} from '../../../utils/componentTestIds'

// File TODO: Update link to publicly-available link to the utility package

function sensorGraphTutorial (props) {
  return (
    <ExpandableSection
      variant='container'
      data-testid={ HOW_TO_ADD_A_GRAPH_SECTION_TID }
      header={
        <Header
          variant='awsui-h1-sticky'
          description={ MessageKeys.expressLink.instructionCards.tutorial.summary }
        >
          { MessageKeys.expressLink.instructionCards.tutorial.title }
        </Header>
      }
    >
      <ol>
        <li>
          { MessageKeys.expressLink.instructionCards.tutorial.steps.step1.micro1 }
        </li>
        <li>
          { MessageKeys.expressLink.instructionCards.tutorial.steps.step2.micro1 }
        </li>
        <li>
          { MessageKeys.expressLink.instructionCards.tutorial.steps.step3.micro1 }
        </li>
      </ol>
    </ExpandableSection>
  )
}
export default sensorGraphTutorial

sensorGraphTutorial.propTypes = {
  updateWorkflow: PropTypes.func,
  deviceName: PropTypes.string
}
