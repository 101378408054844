import React from 'react'

import Dashboard from './components/expresslink/Dashboard/dashboard'
import ExpressLinkFooter from './components/expresslink/ExpressLinkFooter/expressLinkFooter'
import ExpressLinkHeader from './components/expresslink/ExpressLinkHeader/expressLinkHeader'

import './css/expresslink/index.css'
import '@amzn/awsui-global-styles/polaris.css'

function ExpressLinkMain () {
  return (
    <>
      <ExpressLinkHeader/>
      <Dashboard/>
      <ExpressLinkFooter/>
    </>
  )
}

export default ExpressLinkMain
