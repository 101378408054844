import AWS from 'aws-sdk'

// import { CloudFormationClient, DescribeStacksCommand } from '@aws-sdk/client-cloudformation'
import AlphaAWSConfiguration from '../configs/cognito/alpha-aws-configuration.json'
import ALPHAQEMUAWSConfiguration from '../configs/cognito/alpha-qemu-aws-configuration.json'
import BetaAWSConfiguration from '../configs/cognito/beta-aws-configuration.json'
import GammaAWSConfiguration from '../configs/cognito/gamma-aws-configuration.json'
import ProdESPAWSConfiguration from '../configs/cognito/prod-esp-aws-configuration.json'
import ProdExpresslinkAWSConfiguration from '../configs/cognito/prod-expresslink-aws-configuration.json'
import ProdSTAWSConfiguration from '../configs/cognito/prod-st-aws-configuration.json'
import ESPConfig from '../configs/vendors/esp-vendor-template.json'
import QEMUConfig from '../configs/vendors/qemu-vendor-template.json'
import STConfig from '../configs/vendors/st-vendor-template.json'
import { MAXIMUM_RECONNECT_TIME_MS } from './constants'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const AWSIoTData = require('aws-iot-device-sdk')

/*
 * Helper function to validate query parameters from the URL
 * FILE TODO: any follow up actions from pen test
*/
function validateURLQueryParameters (tokens) {
  const validatedTokens = {}
  let isValid = true

  const validityCheckRegex = /^[a-zA-Z0-9_-]+$/

  function isUndefined (obj) {
    return obj === undefined
  }

  // Invalidate board name if it contains spaces.
  if (isUndefined(tokens.boardName) || tokens.boardName === '' || !validityCheckRegex.test(tokens.boardName)) {
    validatedTokens.boardName = 'Invalid board name'
    isValid = false
  } else {
    validatedTokens.boardName = tokens.boardName
  }

  // Invalidate thing name if it contains spaces or more than 128 chars.
  if (isUndefined(tokens.thingName) || tokens.thingName === '' || !validityCheckRegex.test(tokens.thingName) || tokens.thingName.length > 128) {
    validatedTokens.thingName = 'Invalid thing name'
    isValid = false
  } else {
    validatedTokens.thingName = tokens.thingName
  }

  if (isUndefined(tokens.token)) {
    validatedTokens.token = ''
    isValid = false
  } else {
    validatedTokens.token = tokens.token
  }

  validatedTokens.isValid = isValid

  return validatedTokens
}

/*
 * This function extracts any query parameters from the URL
*/
export function getURLQueryParameters () {
  const encodedUrl = window.location.search.split('?')
  let params
  const tokens = {}

  // Need a try catch here as decodeURIComponent will fail if there are non utf-8 characters
  try {
    params = encodedUrl.map(function (elt) {
      return decodeURIComponent(elt)
    })
    // Split into key value
    for (let i = 0; i < params.length; ++i) {
      if (params[i] === '') continue

      const value = params[i].substring(params[i].indexOf('=') + 1)
      const key = params[i].substring(0, params[i].indexOf('='))
      tokens[key] = value
    }
  } catch (e) {
    // This can be empty as validateURLQueryParameters will fail on undefineds.
  }

  return validateURLQueryParameters(tokens)
}

/*
 * This function authenticates the user with Cognito
 * @return The device client that is used for MQTT connection
 * @param in thingName: the name of the thing (also the client ID)
*/
export function createAndGetCognitoIdentity (thingName) {
  const timestamp = Date.now() % 1000

  const clientId = `vis-${thingName}-${timestamp}`

  const AWSConfiguration = getTokensAndCredsByStage()[0]

  AWS.config.region = AWSConfiguration.region

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSConfiguration.identityPoolId
  })

  const client = AWSIoTData.device({
    //
    // Set the AWS region we will operate in.
    //
    region: AWS.config.region,
    host: AWSConfiguration.iotDataEndpoint,
    //
    // Use the clientId created earlier.
    //
    clientId: clientId,
    //
    // Connect via secure WebSocket
    //
    protocol: 'wss',
    //
    // Set the maximum reconnect time to 8 seconds; this is a browser application
    // so we don't want to leave the user waiting too long for reconnection after
    // re-connecting to the network/re-opening their laptop/etc...
    //
    maximumReconnectTimeMs: MAXIMUM_RECONNECT_TIME_MS,
    //
    // Enable console debugging information (optional)
    //
    debug: false,
    //
    // IMPORTANT: the AWS access key ID, secret key, and sesion token must be
    // initialized with empty strings.
    //
    accessKeyId: '',
    secretKey: '',
    sessionToken: ''
  })

  const cognitoIdentity = new AWS.CognitoIdentity()
  AWS.config.credentials.get(function (err) {
    if (!err) {
      const params = {
        IdentityId: AWS.config.credentials.identityId
      }
      cognitoIdentity.getCredentialsForIdentity(params, function (err, data) {
        if (!err) {
          //
          // Update our latest AWS credentials; the MQTT client will use these
          // during its next reconnect attempt.
          //
          client.updateWebSocketCredentials(data.Credentials.AccessKeyId,
            data.Credentials.SecretKey,
            data.Credentials.SessionToken)
        } else {
          console.log('error retrieving credentials: ' + err)
        }
      })
    } else {
      // TODO: This is expected to run at least once (failed case), as it will have to retry.
      // Do we want to print error messages to console?
      console.log('error retrieving identity:' + err)
    }
  })

  return client
}

/*
 * This function Authenticates the user with the Cognito pool.
 * @paramin: client, the device client used for MQTT connection
*/
export function authenticateWithCognito (client) {
  const AWSConfiguration = getTokensAndCredsByStage()[0]
  const authenticationData = getURLQueryParameters()

  const poolData = {
    UserPoolId: AWSConfiguration.userPoolId,
    ClientId: AWSConfiguration.userPoolClientId,
    Storage: window.sessionStorage
  }

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

  if (authenticationData.isValid) {
    const userData = {
      Username: authenticationData.thingName,
      Pool: userPool
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username: authenticationData.thingName,
        Password: authenticationData.token
      }
    )

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        const key = 'cognito-idp.' + AWSConfiguration.region + '.amazonaws.com/' + AWSConfiguration.userPoolId
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: AWSConfiguration.identityPoolId, // your identity pool id here
          Logins: {
            [key]: result.getIdToken().getJwtToken()
          }
        })

        // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        AWS.config.credentials.refresh(error => {
          if (error) {
            console.error(error)
          } else {
            // Update our latest AWS credentials; the MQTT client will use these
            // during its next reconnect attempt.
            client.updateWebSocketCredentials(AWS.config.credentials.accessKeyId,
              AWS.config.credentials.secretAccessKey,
              AWS.config.credentials.sessionToken)
          }
        })
      },

      onFailure: function (err) {
        console.log(err)
      },

      newpasswordRequired: function () {
        throw Object.assign(new Error('demoExpired'), { code: 400 })
      }
    })
  } else {
    console.error('Invalid parameters for authentication.')
  }
}

export function getImageSrcFromBoardName (boardName) {
  // Can't use a switch because it's a .includes() unfortunately
  if (boardName.includes('ESP') || boardName.includes('AWS-REFERENCE')) {
    return '/images/ESP32-C3-Expresslink.png'
  } else if (boardName.includes('Infineon')) {
    return '/images/infineon-ccm.png'
  } else if (boardName.includes('W256')) {
    return '/images/USB-NORA-W256AWS-ublox.png'
  } else if (boardName.includes('SARA-R5')) {
    return '/images/SARA-R5-ublox-Expresslink.jpeg'
  } else if (boardName.includes('Realtek')) {
    return '/images/Realtek-Ameba-Z2.jpeg'
  } else if (boardName.includes('Bravo')) {
    return '/images/Telit-Cinterion-Bravo-AWS-Expresslink.png'
  } else {
  // This should never happen, but just in case, we return the IoT Expresslink logo
    return '/images/AWS-IoT-Expresslink.png'
  }
}

export function getTokensAndCredsByStage () {
  const url = window.location.href

  if (url.includes('localhost')) {
    return [BetaAWSConfiguration, ESPConfig]
  } else if (url.includes('d1fl03ccaehmey.cloudfront.net')) {
    return [ALPHAQEMUAWSConfiguration, QEMUConfig]
  } else if (url.includes('alpha-expresslink') || url.includes('d2m6yq6jtmaiey.cloudfront.net')) {
    return [AlphaAWSConfiguration, ESPConfig]
  } else if (url.includes('beta')) {
    return [BetaAWSConfiguration, STConfig]
  } else if (url.includes('gamma')) {
    return [GammaAWSConfiguration, ESPConfig]
  } else if (url.includes('iot-expresslink')) {
    return [ProdExpresslinkAWSConfiguration, STConfig]
  } else if (url.includes('st.')) {
    return [ProdSTAWSConfiguration, STConfig]
  } else if (url.includes('esp.')) {
    return [ProdESPAWSConfiguration, ESPConfig]
  } else {
    // This should never happen, but just in case, we return the beta config
    return [BetaAWSConfiguration, STConfig]
  }
}
