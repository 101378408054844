import React from 'react'

import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'
import PropTypes from 'prop-types'

import '../../../css/quick-connect/title.css'

import MessageKeys from '../../../languages/en/en.json'
import { getTokensAndCredsByStage, getURLQueryParameters } from '../../../utils/utils'

/*
 * This function maintains the title, or the top section of the screen of the visualizer
 */
function Title (props) {
  const params = getURLQueryParameters()
  const [, vendorTemplate] = getTokensAndCredsByStage()
  return (
    <>
      <div className="title-container">
        <h1>
          {MessageKeys.title.header}
        </h1>
        <p>
          {MessageKeys.title.subheaderStart + params.boardName + MessageKeys.title.subheaderEnd}
        </p>
        <div className="title-header">
          <h3>{params.boardName}</h3>
          {MessageKeys.title.connected.thingId + params.thingName} <br/>
        </div>
        <p className={props.connected ? 'connected-text' : 'searching-text'}>
          <StatusIndicator className='status-indicator' type={props.connected ? 'success' : 'loading'}/>
          {props.connected ? 'Connected' : 'Searching'}
        </p>
        <img src={vendorTemplate.pictureUrl} alt="Board Picture"/>
      </div>
    </>
  )
}

Title.propTypes = {
  connected: PropTypes.bool
}

export default Title
