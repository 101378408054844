import * as React from 'react'

import Button from '@amzn/awsui-components-react/polaris/button'
import Header from '@amzn/awsui-components-react/polaris/header'
import Popover from '@amzn/awsui-components-react/polaris/popover'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'
import PropTypes from 'prop-types'

import MessageKeys from '../../../languages/en/en.json'
import {
  COPY_DASHBOARD_URL_BUTTON_TID,
  COPY_DASHBOARD_URL_POPOVER_CONTENT_TID
} from '../../../utils/componentTestIds'
import '../../../css/expresslink/title.css'

// File TODO:
// do we need to support IE/older browsers? If not we can remove the compatibility code

function Title (props) {
  return (
    <Header
      className='dashboard-title'
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Popover
            className='copy-dashboard-link-popover'
            dismissButton={ false }
            size='small'
            triggerType='custom'
            position='left'
            content={
              <StatusIndicator
                type='success'
                data-testid={ COPY_DASHBOARD_URL_POPOVER_CONTENT_TID }
              >
                { MessageKeys.expressLink.dashboardTitle.copyDashboardLink.popoverContentText }
              </StatusIndicator>
            }
          >
            <Button
              onClick={
                async (_) => await copyToClipboard(window.location.href)
              }
              iconAlign='left'
              iconName='copy'
              data-testid={ COPY_DASHBOARD_URL_BUTTON_TID }
            >
              { MessageKeys.expressLink.dashboardTitle.copyDashboardLink.buttonText }
            </Button>
          </Popover>
        </SpaceBetween>
      }
      description={ MessageKeys.title.subheaderStart + ` ${props.boardName} ` + MessageKeys.title.subheaderEnd }
    >
      { MessageKeys.title.header }
    </Header>
  )
}

async function copyToClipboard (stringToCopy) {
  // test to see if Clipboard API is available for current browser
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(stringToCopy)
  } else {
    // workaround (older way to copy)
    const stubElement = document.createElement('textarea')
    stubElement.value = stringToCopy
    stubElement.setAttribute('readonly', '')
    stubElement.style.position = 'absolute'
    stubElement.style.left = '-9999px'
    document.body.appendChild(stubElement)
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
    stubElement.select()
    document.execCommand('copy')
    document.body.removeChild(stubElement)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
  }
}

Title.propTypes = {
  boardName: PropTypes.string
}

export default Title
