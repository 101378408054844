import * as React from 'react'

import Button from '@amzn/awsui-components-react/polaris/button'
import Container from '@amzn/awsui-components-react/polaris/container'
import Header from '@amzn/awsui-components-react/polaris/header'

import MessageKeys from '../../../languages/en/en.json'
import {
  CONNECT_TO_AWS_BUTTON_TID
} from '../../../utils/componentTestIds'

function GraduateToAWS () {
  return (
    <Container
      header={
        <Header
          variant='h2'
          description={ MessageKeys.expressLink.instructionCards.graduation.description }
          actions={
            <Button
              href='https://docs.aws.amazon.com/iot-expresslink/index.html'
              iconAlign='right'
              iconName='external'
              target='_blank'
              data-testid={ CONNECT_TO_AWS_BUTTON_TID }
            >
              { MessageKeys.expressLink.instructionCards.graduation.cta }
            </Button>
          }
        >
          { MessageKeys.expressLink.instructionCards.graduation.header }
        </Header>
      }
      disableContentPaddings
    />
  )
}

export default GraduateToAWS
