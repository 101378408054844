import * as React from 'react'

import Box from '@amzn/awsui-components-react/polaris/box'
// import Link from '@amzn/awsui-components-react/polaris/link'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'

import '../../../css/expresslink/footer.css'
import MessageKeys from '../../../languages/en/en.json'

// File TODO: style - change link color/font

function ExpressLinkFooter () {
  return (
    <div id='f' className='expresslink-footer'>
      <div className='footer-content'>
        <SpaceBetween className='footer-items' direction='horizontal' size='m'>
          <Box variant='p' color='text-body-secondary'>
            { MessageKeys.expressLink.footer.copyright }
          </Box>
          <a
            className='footer-link'
            href='https://aws.amazon.com/privacy/'
            target='_blank noreferrer'
          >
            { MessageKeys.expressLink.footer.privacyPolicy }
          </a>
          <a
            className='footer-link'
            href='https://aws.amazon.com/service-terms/'
            target='_blank noreferrer'
          >
            { MessageKeys.expressLink.footer.termsOfUse }
          </a>
        </SpaceBetween>
      </div>
    </div>
  )
}

export default ExpressLinkFooter
