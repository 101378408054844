import React, { useState } from 'react'

import PropTypes from 'prop-types'

import '../../../../freertosWebsite/css/card.css'

import MessageKeys from '../../../../languages/en/en.json'
import { NOT_STARTED } from '../../../../utils/constants'
import { getTokensAndCredsByStage } from '../../../../utils/utils'

/*
 * This function returns the instruction card for adding a new sensor graph.
 * NB: The carousel is 1 indexed, as we wanted to correspond those to the step numbers
 * FILE TODO: Abstract this better... this code is gross.
 */
function sensorGraphTutorial (props) {
  const [carouselIndex, updateCarouselIndex] = useState(1)
  const vendorTemplate = getTokensAndCredsByStage()
  let carouselJSX

  if (vendorTemplate[1].vendorName === 'ST') {
    // ST
    switch (carouselIndex) {
    case 1:
      carouselJSX = <>
        <h4>
          {MessageKeys.instructionCards.tutorials.st.steps.step1.header}<i>{MessageKeys.instructionCards.tutorials.st.steps.step1.headerItalics}</i>
        </h4>
        <ul className='micro'>
          <li>
            {MessageKeys.instructionCards.tutorials.st.steps.step1.micro1} <a href="https://st.com" target="_blank" rel="noopener noreferrer">ST.com.</a>
          </li>
          <li>{MessageKeys.instructionCards.tutorials.st.steps.step1.micro2}</li>
          <li>
            {MessageKeys.instructionCards.tutorials.st.steps.step1.micro3} <a href="https://www.st.com/en/development-tools/stm32cubeprog.html" target="_blank" rel="noopener noreferrer">STM32CubeProgrammer.</a>
          </li>
          <li>
            {MessageKeys.instructionCards.tutorials.st.steps.step1.micro4} <a href="https://www.st.com/en/ecosystems/stm32cube.html" target="_blank" rel="noopener noreferrer">STM32Cube.</a>
          </li>
          <li>
            {MessageKeys.instructionCards.tutorials.st.steps.step1.micro5} <a href="https://www.st.com/content/dam/AME/2021/mdg/stm32l4-aws-quickconnect.zip" target="_blank" rel="noopener noreferrer">source code.</a>
          </li>
          <li>
            {MessageKeys.instructionCards.tutorials.st.steps.step1.micro6} <a href="https://www.st.com/en/embedded-software/x-cube-aws.html" target="_blank" rel="noopener noreferrer">X-CUBE-AWS.</a>
          </li>
        </ul>
      </>
      break
    case 2:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.st.steps.step2.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step2.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step2.micro2} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step2.micro3} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step2.micro4} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step2.micro5} </li>
        </ul></>
      break
    case 3:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.st.steps.step3.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step3.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step3.micro2} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step3.micro3} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step3.micro4} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step3.micro5} </li>
        </ul></>
      break
    case 4:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.st.steps.step4.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step4.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step4.micro2} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step4.micro3} <br/><code>{MessageKeys.instructionCards.tutorials.st.steps.step4.micro3CodeBlock} </code></li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step4.micro4} </li>
        </ul></>
      break
    case 5:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.st.steps.step5.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step5.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step5.micro2} </li>
          <li> {MessageKeys.instructionCards.tutorials.st.steps.step5.micro3} </li>
        </ul></>
    }
  } else if (vendorTemplate[1].vendorName === 'ESP') {
    switch (carouselIndex) {
    case 1:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} <i>{MessageKeys.instructionCards.tutorials.esp.steps.step1.headerItalics}</i> </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
      break
    case 2:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.esp.steps.step2.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step2.micro1} <a href='https://github.com/espressif/aws-quickconnect' target="_blank" rel="noopener noreferrer"> here</a>{MessageKeys.instructionCards.tutorials.esp.steps.step2.micro1After}<br/> </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step2.micro2} </li>
        </ul></>
      break
    case 3:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.esp.steps.step3.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step3.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step3.micro2} </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step3.micro3} </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step3.micro4} </li>
        </ul></>
      break
    case 4:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.esp.steps.step4.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step4.micro1}  <code>{MessageKeys.instructionCards.tutorials.esp.steps.step4.micro1CodeBlock} </code> </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step4.micro2}  <code>{MessageKeys.instructionCards.tutorials.esp.steps.step4.micro2CodeBlock}</code>{MessageKeys.instructionCards.tutorials.esp.steps.step4.micro2After} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/establish-serial-connection.html' target="_blank" rel="noopener noreferrer"> this guide.</a></li>
        </ul></>
      break
    case 5:
      carouselJSX = <><h4> {MessageKeys.instructionCards.tutorials.st.steps.step5.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step5.micro1} </li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step5.micro2} <code>{MessageKeys.instructionCards.tutorials.esp.steps.step4.micro2CodeBlock} </code></li>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step5.micro3} </li>
        </ul></>
    }
  } else {
    // QEMU
    switch (carouselIndex) {
    case 1:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
      break
    case 2:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
      break
    case 3:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
      break
    case 4:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
      break
    case 5:
      carouselJSX = <> <h4> {MessageKeys.instructionCards.tutorials.esp.steps.step1.header} </h4>
        <ul className='micro'>
          <li> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1} <a href='https://docs.espressif.com/projects/esp-idf/en/latest/esp32c3/get-started/index.html' target="_blank" rel="noopener noreferrer"> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1Link}</a> {MessageKeys.instructionCards.tutorials.esp.steps.step1.micro1After}</li>
        </ul> </>
    }
  }

  return (
    <tr>
      <td colSpan='2'>
        <div className="fr-cards-section fr-homepage-bordered-section">
          <ul className="fr-cards-list">
            <li>
              <div className='tutorial'>
                <h3 className="fr-section-subtitle">{vendorTemplate[1].vendorName === 'ST' ? MessageKeys.instructionCards.tutorials.st.header : vendorTemplate[1].vendorName === 'ESP' ? MessageKeys.instructionCards.tutorials.esp.header : MessageKeys.instructionCards.tutorials.qemu.header}</h3>
                <div> {vendorTemplate[1].vendorName === 'ST' ? MessageKeys.instructionCards.tutorials.st.subheader : vendorTemplate[1].vendorName === 'ESP' ? MessageKeys.instructionCards.tutorials.esp.subheader : MessageKeys.instructionCards.tutorials.qemu.subheader} </div>
                <hr/>
                <div className='radio-wrapper'>
                  <input type="radio" name='carousel' value='1' readOnly test-id='carousel1' checked={carouselIndex === 1} onClick={() => updateCarouselIndex(1)}/>
                  <input type="radio" name='carousel' value='2' readOnly test-id='carousel2' checked={carouselIndex === 2} onClick={() => updateCarouselIndex(2)}/>
                  <input type="radio" name='carousel' value='3' readOnly test-id='carousel3' checked={carouselIndex === 3} onClick={() => updateCarouselIndex(3)}/>
                  <input type="radio" name='carousel' value='4' readOnly test-id='carousel4' checked={carouselIndex === 4} onClick={() => updateCarouselIndex(4)}/>
                  <input type="radio" name='carousel' value='5' readOnly test-id='carousel5' checked={carouselIndex === 5} onClick={() => updateCarouselIndex(5)}/>
                </div>
                {carouselJSX}
              </div>
              <a onClick={() => props.updateWorkflow(NOT_STARTED)} className='cancel'> Cancel </a>
              {carouselIndex > 1
                ? <a onClick={() => updateCarouselIndex(carouselIndex - 1)}>
                  <div className='fr-button fr-variant-secondary previous-button' test-id='previous-slide-button'>
                  Previous
                  </div>
                </a>
                : <a onClick={() => props.updateWorkflow(NOT_STARTED)}>
                  <div className='fr-button fr-variant-secondary previous-button' test-id='close-tutorial-button'>
                Previous
                  </div>
                </a>}

              {carouselIndex < 5
                ? <a onClick={() => updateCarouselIndex(carouselIndex + 1)}>
                  <div className='fr-button fr-variant-secondary next-button' test-id='next-slide-button'>
                  Next
                  </div>
                </a>
                : <a onClick={() => props.updateWorkflow(NOT_STARTED)}>
                  <div className='fr-button fr-variant-secondary next-button' test-id='finish-tutorial-button'>
                Done
                  </div>
                </a>}
            </li>
          </ul>
        </div>
      </td>
    </tr>
  )
}

export default sensorGraphTutorial

sensorGraphTutorial.propTypes = {
  updateWorkflow: PropTypes.func,
  deviceName: PropTypes.string
}
