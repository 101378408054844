import React from 'react'

import '../../../css/quick-connect/configurationForm.css'

import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'

import MessageKeys from '../../../languages/en/en.json'
import { getTokensAndCredsByStage } from '../../../utils/utils'

/*
 * This class is for the content of the configurations tab, i.e. instructions for reconfiguring wifi
 */
class Configurations extends React.Component {
  constructor () {
    super()
    this.state = {
      vendorConfig: getTokensAndCredsByStage()[1]
    }
  }

  render () {
    return (
      <div className="tab-body">
        <ul className="instruction-list">
          <li>{MessageKeys.configurations.header}</li>
          <li><StatusIndicator className='icon' type='info'/> <p>{MessageKeys.configurations.disclaimer}</p></li>
        </ul>

        <ul className="instruction-list">
          <li><b>{MessageKeys.global.stepNumbers.step1} </b> {MessageKeys.configurations.steps.step1} </li>
          <li><b>{MessageKeys.global.stepNumbers.step2} </b> {MessageKeys.configurations.steps.step2} </li>
          <li><b>{MessageKeys.global.stepNumbers.step3} </b> {MessageKeys.configurations.steps.step3} </li>
          <li><b>{MessageKeys.global.stepNumbers.step4} </b> {MessageKeys.configurations.steps.step4} </li>
        </ul>

      </div>
    )
  }
}

export default Configurations
