import React from 'react'

import '../../../../freertosWebsite/css/card.css'

import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator'

import MessageKeys from '../../../../languages/en/en.json'

/*
 * This function returns the error card for when there has been no data from the device in 60 seconds
 */
function SlowDataErrorCard (props) {
  return (
    <div>
      <StatusIndicator className='icon' type='warning'/>
      <div className="error-card-wrapper">
        <h3 className="fr-section-subtitle">{MessageKeys.instructionCards.errors.slowData.header}</h3>
        <p className="error-card-text"> {MessageKeys.instructionCards.errors.slowData.subheader} </p>
      </div>
    </div>
  )
}

export default SlowDataErrorCard
